<template>
  <div class="sub-header">
    <div class="sub-header__wrap container flex">
      <h1 class="sub-header__title main-title ">Медицинский кабинет</h1>
      <div class="sub-header__right">
        <p class="sub-header__text">{{ from }} - {{ to }} из {{ pageCount }}</p>
        <button :disabled="pageNumber == 1" class="sub-header__box" @click="prevPage()">
          <img v-if="pageNumber == 1" class="sub-header__box-icon" src="@/assets/png/database-icon.png" />
          <img v-else class="sub-header__box-icon" src="@/assets/png/database-icon-active.png" alt="" style="transform: rotate(180deg)"/>
        </button>
        <button :disabled="pageCount <= to" class="sub-header__box"  @click="nextPage()">
          <img  v-if="pageCount > to" class="sub-header__box-icon" src="@/assets/png/database-icon-active.png" alt=""/>
          <img  v-else class="sub-header__box-icon" src="@/assets/png/database-icon.png" alt="" style="transform: rotate(180deg)"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props:{
    pageNumber:{
      type: Number,
      default: 0
    },
    pageCount: {
      type: Number,
      default: 0
    },
    resetPag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "",
      showBlock: false,
      from : 0,
      to : 0
    };
  },
  methods:{
      nextPage(){
        this.$emit('nextPage');
        this.from = this.from + 10;
        this.to = this.to + 10;
        if(this.to > this.pageCount){
          this.to = this.pageCount;
        }
      },
      prevPage(){
        this.$emit('prevPage');
        this.from = this.from - 10;
        if(this.to == this.pageCount){
          this.to = this.from + 9;
        }else{
          this.to = this.to - 10;
        }
      }
  },
  computed: {
    ...mapState(['RoutesDrivers'])
  },
  mounted() {
    this.from = this.pageNumber
    if(this.pageNumber + 9 > this.pageCount){
      this.to = this.pageCount
    }else{
      this.to = this.pageNumber + 9
    }
  },
  watch: {
    resetPag: function(){
      this.from = this.pageNumber
      if(this.pageCount > 10){
        this.to = this.pageNumber + 9 
      }else{
        this.to = this.pageCount
      }
    }
  },
}
</script>

<style scoped>
.sub-header {
  background-color: #fff;
  /* box-sizing: border-box; */
  height: 70px;
}
.sub-header__right {
  display: inline-block;
}
.sub-header__title {
  font-weight: 500;
font-size: 36px;
line-height: 46px;
}
.sub-header__text {
  margin: 30px 20px 0 25px;
  color: #707070;
  display: inline-block;
}

.sub-header__box {
  min-width: 36px;
  height: 36px;
  line-height: 16px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box; 
  border-radius: 8px;
}
.sub-header__box:not(:last-child) {
  margin-right: 18px;
}
</style>
